import axios from 'axios'
import ReactGA from 'react-ga'

export const getGoogleCalendarEvents = async () => {
    return axios.get(
        `https://www.googleapis.com/calendar/v3/calendars/${process.env.REACT_APP_GOOGLE_CALENDAR_ID}/events`,
        {
            params: {
                maxResults: 6,
                key: 'AIzaSyDIQhdEyE0SxFL3eHBtIa5_wZb4bSb3auI',
                orderBy: 'startTime',
                singleEvents: true,
                timeMin: new Date(),
            },
        }
    )
}

export const getYoutubePlaylists = async () => {
    return axios.get('https://www.googleapis.com/youtube/v3/playlists', {
        params: {
            part: 'snippet',
            maxResults: 50,
            key: process.env.REACT_APP_YOUTUBE_API_KEY,
            channelId: process.env.REACT_APP_YOUTUBE_CHANNEL_ID,
        },
    })
}

export const initializeGoogleAnalytics = async () => {
    try {
        await ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    } catch (e) {
        console.log('Failed to initialize Google Analytics', e)
    }

    return
}

export const trackPageView = async (location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
}

export const trackEvent = async (action) => {
    ReactGA.event({ category: action, action })
}
