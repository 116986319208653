import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { BasePage } from '../../components/base-page/BasePage'
import { trackEvent, trackPageView } from '../../services/GoogleApiService'
import { connect } from 'react-redux'
import emailjs from 'emailjs-com'

export class ContactUsPage extends Component {
    componentDidMount() {
        trackPageView(window.location)
    }

    state = {
        showEmailSuccess: false,
    }

    handleOnSubmit = (e) => {
        e.preventDefault()
        trackEvent(`Submit Contact Form`)
        emailjs
            .sendForm(
                process.env.EMAIL_JS_SERVICE_ID,
                process.env.EMAIL_JS_TEMPLATE_ID,
                e.target,
                process.env.EMAIL_JS_USER_ID
            )
            .then(
                (result) => {
                    console.log(result.text)
                    this.setState({ showEmailSuccess: true })
                },
                (error) => {
                    console.log(error.text)
                }
            )
        e.target.reset()
    }

    render() {
        return (
            <BasePage
                breadcrumbTitle={'Contact Us'}
                pageTitle={'Contact Us'}
                cssClasses={'contact-us'}
            >
                <Row>
                    <Col>
                        <h2>Our Location</h2>
                        <iframe
                            width="100%"
                            height="450"
                            frameBorder="0"
                            style={{ border: 0 }}
                            title={'Remnant Messengers Location'}
                            allowFullScreen=""
                            src="https://www.google.com/maps/embed/v1/place?q=Dominican+Republic&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                        ></iframe>
                    </Col>
                </Row>
                <Row className={'mt-5 pb-3'}>
                    <Col>
                        <h5>
                            <strong>Email:&nbsp;</strong>
                            {process.env.REACT_APP_ORGANIZATION_EMAIL}
                        </h5>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        {!this.state.showEmailSuccess && (
                            <Form
                                onSubmit={this.handleOnSubmit}
                                method="POST"
                                target="_blank"
                            >
                                <div className="mb-3 pt-0">
                                    <Input
                                        type="text"
                                        placeholder="Your name"
                                        name="user_name"
                                        required
                                    />
                                </div>
                                <div className="mb-3 pt-0">
                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        name="user_email"
                                        required
                                    />
                                </div>
                                <div className="mb-3 pt-0">
                                    <Input
                                        placeholder="Your message"
                                        name="user_message"
                                        type="textarea"
                                        required
                                    />
                                </div>
                                <div className="mb-3 pt-0">
                                    <Button type="submit">
                                        Send a message
                                    </Button>
                                </div>
                            </Form>
                        )}
                        {this.state.showEmailSuccess && (
                            <div>
                                <p>{Strings.email_success}</p>
                            </div>
                        )}
                    </Col>
                </Row> */}
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(ContactUsPage)
