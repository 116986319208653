import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import './Footer.scss'

export class Footer extends Component {
    render() {
        return (
            <footer>
                <Container fluid>
                    <Row>
                        <Col sm={'12'} md={'6'}>
                            <span className="copyright">
                                {`Copyright © ${process.env.REACT_APP_ORGANIZATION_NAME} 2022`}
                            </span>
                        </Col>
                        <Col sm={'12'} md={'6'} className={'social-buttons'}>
                            <div className="button-wrapper">
                                <a
                                    href={
                                        this.props.language === 'en'
                                            ? process.env.REACT_APP_INSTAGRAM_EN
                                            : process.env.REACT_APP_INSTAGRAM_ES
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon
                                        icon={['fab', 'instagram']}
                                    />
                                </a>
                                <a
                                    href={
                                        this.props.language === 'en'
                                            ? process.env.REACT_APP_FACEBOOK_EN
                                            : process.env.REACT_APP_FACEBOOK_ES
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon
                                        icon={['fab', 'facebook']}
                                    />
                                </a>

                                <a
                                    href={
                                        this.props.language === 'en'
                                            ? process.env.REACT_APP_YOUTUBE_EN
                                            : process.env.REACT_APP_YOUTUBE_ES
                                    }
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon
                                        icon={['fab', 'youtube']}
                                    />
                                </a>

                                <a
                                    href={`mailto:${process.env.REACT_APP_ORGANIZATION_EMAIL}`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fa', 'envelope']}
                                    />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(Footer)
