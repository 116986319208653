import React, { useState } from 'react'
import { Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { createPortal } from 'react-dom'
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
} from 'reactstrap'
import { Strings } from '../../localizations'
import { trackEvent } from '../../services/GoogleApiService'
const PlaylistCategory = (props) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin()
    const [shown, setShown] = useState('')

    const modalBody = (pdfUrl) => (
        <div
            style={{
                backgroundColor: '#fff',
                flexDirection: 'column',
                overflowY: 'auto',

                /* Fixed position */
                left: 0,
                position: 'fixed',
                top: 0,

                /* Take full size */
                height: '100%',
                width: '100%',

                /* Displayed on top of other elements */
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#000',
                    color: '#fff',
                    display: 'flex',
                    padding: '.5rem',
                    boxShadow: '10px 5px 5px #red',
                }}
            >
                <div style={{ marginRight: 'auto' }}></div>
                <button
                    style={{
                        backgroundColor: '#357edd',
                        border: 'none',
                        borderRadius: '4px',
                        color: '#ffffff',
                        cursor: 'pointer',
                        padding: '8px',
                    }}
                    onClick={() => setShown('')}
                >
                    Close
                </button>
            </div>
            <div
                style={{
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <Viewer
                    fileUrl={pdfUrl}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </div>
        </div>
    )
    return (
        <>
            <div className="level-category">
                <h2 className="category-title subtitle-header">
                    {props.category.name}
                </h2>
                <Row>
                    {props.category.playlists &&
                        props.category.playlists.map((playlist, index) => {
                            return (
                                <Col key={index}>
                                    <Card
                                        style={{
                                            maxWidth: '25rem',
                                        }}
                                        className="my-4"
                                    >
                                        <img
                                            alt={playlist.title}
                                            src={playlist.imageUrl}
                                        />
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                {playlist.title}
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 subtitle"
                                                tag="h6"
                                            >
                                                {playlist.tag}
                                            </CardSubtitle>
                                            <CardText>
                                                {playlist.description}
                                            </CardText>
                                            {!playlist.comingSoon && (
                                                <Button
                                                    className="enabled-pdf-btn"
                                                    onClick={() => {
                                                        trackEvent(
                                                            `Study Guide: ${playlist.title}`
                                                        )
                                                        setShown(playlist.url)
                                                    }}
                                                >
                                                    {Strings.read_more}
                                                </Button>
                                            )}

                                            {playlist.comingSoon && (
                                                <Button disabled={true}>
                                                    {Strings.coming_soon}
                                                </Button>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })}
                </Row>
            </div>
            {shown !== '' && createPortal(modalBody(shown), document.body)}
        </>
    )
}

export default PlaylistCategory
