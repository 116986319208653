import { levels } from './pages/new-members/curriculum'

const defaultLang = localStorage.getItem('language') || 'en'

export const rootReducer = function (
    state = { language: defaultLang, curriculumLevels: levels() },
    action
) {
    switch (action.type) {
        case 'UPDATE_LANG':
            return {
                ...state,
                language: action.payload,
                curriculumLevels: levels(),
            }
        default:
            return state
    }
}
