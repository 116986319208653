import React, { Component } from 'react'
import { Button, Col, Row, Card, CardBody } from 'reactstrap'
import { BasePage } from '../../components/base-page/BasePage'

import './SermonArchivesPage.scss'
import { trackPageView } from '../../services/GoogleApiService'
import { Strings } from '../../localizations'
import { connect } from 'react-redux'

export class SermonArchivesPage extends Component {
    constructor(props) {
        super(props)
        this.state = { videos: [] }
    }
    async componentDidMount() {
        try {
            trackPageView(window.location)
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        return (
            <BasePage
                breadcrumbTitle={Strings.teaching_archive}
                pageTitle={Strings.teaching_archive}
                cssClasses={'sermon-archives'}
            >
                <Row>
                    <Col xs={'12'} sm={'6'} md={'4'} lg={'4'}>
                        <Card className={'mb-3 sermon-card'}>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <img
                                            src={
                                                'https://d1biklpwskup2.cloudfront.net/Functions_Of_Human_Spirit_No_Title.png'
                                            }
                                            alt="..."
                                            className="img-thumbnail"
                                        />
                                    </Col>
                                </Row>
                                <Row className={'video-description'}>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Button
                                                    className={'mb-2 mt-2 py-0'}
                                                    block
                                                    onClick={() => {
                                                        window.open(
                                                            `https://www.youtube.com/playlist?list=1`
                                                        )
                                                    }}
                                                >
                                                    <small>
                                                        {Strings.watch_online}
                                                    </small>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className={'video-title'}>
                                                    {
                                                        Strings.featured_sermon_title
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(SermonArchivesPage)
