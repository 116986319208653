import React, { Component } from 'react'
import {
    Container,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselControl,
    CarouselCaption,
    Button,
    Row,
    Col,
    NavLink,
} from 'reactstrap'
import './HomePage.scss'
import { NoticeBar } from '../../components/notice-bar/NoticeBar'
import { Footer } from '../../components/footer/Footer'
import {
    getGoogleCalendarEvents,
    trackEvent,
    trackPageView,
} from '../../services/GoogleApiService'
import ReactGA from 'react-ga'
import moment from 'moment'
import { Strings } from '../../localizations'
import { BasePage } from '../../components/base-page/BasePage'
import { connect } from 'react-redux'
const items = [
    {
        src: 'https://d1biklpwskup2.cloudfront.net/collage-family-banner-2.png',
        altText: '',
        caption: '',
        header: '',
    },

    {
        src: 'https://d1biklpwskup2.cloudfront.net/EN_SocialMediaCover.png',
        altText: '',
        caption: '',
        header: '',
    },

    {
        src: 'https://d1biklpwskup2.cloudfront.net/Joel_Army2x.png',
        altText: '',
        caption: '',
        header: '',
    },
]

const esItems = [
    {
        src: 'https://d1biklpwskup2.cloudfront.net/ES_Beach_Daniel_11_Cover2x.png',
        altText: '',
        caption: '',
        header: '',
    },

    {
        src: 'https://d1biklpwskup2.cloudfront.net/ES_SocialMediaCover.png',
        altText: '',
        caption: '',
        header: '',
    },

    {
        src: 'https://d1biklpwskup2.cloudfront.net/ES_+Joel_Army2x.png',
        altText: '',
        caption: '',
        header: '',
    },
]

export class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = { activeIndex: 0, upcomingEvents: [] }
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
        this.goToIndex = this.goToIndex.bind(this)
        this.onExiting = this.onExiting.bind(this)
        this.onExited = this.onExited.bind(this)
    }

    componentDidMount = async () => {
        trackPageView(window.location)
        try {
            const upcomingEvents = await getGoogleCalendarEvents()
            this.setState({ upcomingEvents: upcomingEvents.data.items })
        } catch (err) {
            console.log('Error retrieving google calendar events', err)
        }
    }

    onExiting() {
        this.animating = true
    }

    onExited() {
        this.animating = false
    }

    next() {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === items.length - 1
                ? 0
                : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }

    previous() {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === 0
                ? items.length - 1
                : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex })
    }

    goToIndex(newIndex) {
        if (this.animating) return
        this.setState({ activeIndex: newIndex })
    }

    redirectToEvents() {
        window.location.href = '/events'
    }

    render() {
        let endOfWeek = moment()
            .endOf('week')
            .set('hours', 18)
            .set('minutes', 45)
            .set('seconds', 0)
        const { activeIndex } = this.state
        const slides = items.map((item, index) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption
                        captionText={item.caption}
                        captionHeader={item.caption}
                    />
                    {item.showEventsLink && (
                        <Button
                            color={'primary'}
                            className={'conference-registration-btn'}
                            onClick={this.redirectToEvents}
                        >
                            Learn More
                        </Button>
                    )}
                </CarouselItem>
            )
        })
        const esSlides = esItems.map((item, index) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption
                        captionText={item.caption}
                        captionHeader={item.caption}
                    />
                    {item.showEventsLink && (
                        <Button
                            color={'primary'}
                            className={'conference-registration-btn'}
                            onClick={this.redirectToEvents}
                        >
                            Learn More
                        </Button>
                    )}
                </CarouselItem>
            )
        })
        return (
            <BasePage
                hideBreadCrumb={true}
                breadcrumbTitle={' '}
                cssClasses={'home'}
                pageTitle={' '}
                fluid={true}
            >
                <Container fluid>
                    <Carousel
                        activeIndex={activeIndex}
                        interval={4000}
                        next={this.next}
                        previous={this.previous}
                        ride={'carousel'}
                        pause={'hover'}
                    >
                        <CarouselIndicators
                            items={items}
                            activeIndex={activeIndex}
                            onClickHandler={this.goToIndex}
                        />
                        {this.props.language === 'en' && slides}
                        {this.props.language === 'es' && esSlides}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={this.previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={this.next}
                        />
                    </Carousel>
                    <NoticeBar
                        eventDate={endOfWeek._d}
                        eventName={Strings.shabbat_service}
                    />
                </Container>
                <Container fluid className={'featured-blocks'}>
                    <Row>
                        <Col
                            sm={'12'}
                            md={'4'}
                            className={'featured-block position-relative'}
                        >
                            <NavLink href={'/our-ministers'}>
                                <img
                                    src="https://d1biklpwskup2.cloudfront.net/foot-washing-copy.png"
                                    alt="our ministers"
                                    className={'img-thumbnail'}
                                />
                                <strong>
                                    {Strings.our_ministers}&nbsp;&nbsp;&nbsp;
                                </strong>
                                <span className="more">
                                    {Strings.view_ministers}
                                </span>
                            </NavLink>
                        </Col>
                        <Col
                            sm={'12'}
                            md={'4'}
                            className={'featured-block position-relative'}
                        >
                            <NavLink href={'/newcomer'}>
                                <img
                                    src="https://d1biklpwskup2.cloudfront.net/Church-as-Family.jpg"
                                    alt="newhere"
                                    className={'img-thumbnail'}
                                />
                                <strong>
                                    {Strings.new_here}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </strong>
                                <span className="more">
                                    {Strings.new_members}
                                </span>
                            </NavLink>
                        </Col>
                        <Col
                            sm={'12'}
                            md={'4'}
                            className={'featured-block position-relative'}
                        >
                            <NavLink href={'/newcomer'}>
                                <img
                                    src="https://d1biklpwskup2.cloudfront.net/book-bible.jpg"
                                    alt="sermons"
                                    className={'img-thumbnail'}
                                />
                                <strong>{Strings.teaching_archive}</strong>
                                <div className="more">
                                    <span>{Strings.view_teachings}</span>
                                </div>
                            </NavLink>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={'12'} md={'8'} className={'more-events'}>
                            <h4 className={'title'}>
                                {Strings.more_coming_events}
                            </h4>
                            <div className={'event-listing'}>
                                {this.state.upcomingEvents.map(
                                    (event, index) => {
                                        return (
                                            <div
                                                className={'upcoming-event'}
                                                key={index}
                                            >
                                                <div
                                                    className={'date-container'}
                                                >
                                                    <p className={'event-date'}>
                                                        {moment(
                                                            event.start.dateTime
                                                        ).get('date')}
                                                    </p>
                                                    <p
                                                        className={
                                                            'event-date-month'
                                                        }
                                                    >
                                                        {moment(
                                                            event.start.dateTime
                                                        ).format('MMM')}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        'summary-container'
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            'event-summary'
                                                        }
                                                    >
                                                        {event.summary}
                                                    </span>
                                                    <p
                                                        className={
                                                            'event-start-time'
                                                        }
                                                    >
                                                        {`${moment(
                                                            event.start.dateTime
                                                        ).format(
                                                            'dddd | h:mm A'
                                                        )} AST`}
                                                    </p>
                                                    <a
                                                        className={
                                                            'event-details btn btn-default btn-sm btn-outline-secondary'
                                                        }
                                                        href={event.htmlLink}
                                                        onClick={() =>
                                                            trackEvent(
                                                                `Event Details: ${event.summary}`
                                                            )
                                                        }
                                                    >
                                                        Details
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </Col>
                        <Col sm={'12'} md={'4'} className={'featured-sermon'}>
                            <h4 className={'title'}>
                                {Strings.featured_sermon}
                            </h4>
                            <div className={'sermon-container'}>
                                <ReactGA.OutboundLink
                                    className={'sermon-title'}
                                    eventLabel={'Featured Sermon'}
                                    to={
                                        this.props.language === 'en'
                                            ? process.env
                                                  .REACT_APP_FEATURED_SERMON_EN
                                            : process.env
                                                  .REACT_APP_FEATURED_SERMON_ES
                                    }
                                    target="_blank"
                                >
                                    {Strings.featured_sermon_title}
                                </ReactGA.OutboundLink>
                                <ReactGA.OutboundLink
                                    eventLabel={'Featured Sermon'}
                                    className={'sermon-title'}
                                    to={
                                        process.env
                                            .REACT_APP_FEATURED_SERMON_URL
                                    }
                                    target="_blank"
                                >
                                    {/* <div className={'sermon-thumbnail'} /> */}

                                    {/* <FontAwesomeIcon icon={['fab', 'youtube']} /> */}
                                </ReactGA.OutboundLink>
                                <img
                                    src="https://d1biklpwskup2.cloudfront.net/Functions_Of_Human_Spirit_No_Title.png"
                                    alt="featured sermon"
                                    height="350px"
                                    className="sermon-thumbnail img-thumbnail mx-auto d-block"
                                />
                                <p className={'sermon-description'}>
                                    {Strings.featured_sermon_description}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <p style={{ visibility: 'hidden' }}>
                        {this.props.language}
                    </p>
                </Container>
                <Footer />
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(HomePage)
