import React from 'react'
import './index.css'
import App from './App'
// import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.css'
import { initializeGoogleAnalytics } from './services/GoogleApiService'

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store'
import HOENavbar from './components/navbar/HOENavbar'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <HOENavbar />
        <App />
    </Provider>
)
initializeGoogleAnalytics()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
