import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.scss'
import HomePage from './pages/home/HomePage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faCalendarAlt,
    faEnvelope,
    faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import UpcomingEventsPage from './pages/events/UpcomingEventsPage'
import ContactUsPage from './pages/contact-us/ContactUsPage'
import NewMembersPage from './pages/new-members/NewMembersPage'
import OurBeliefsPage from './pages/about-us/OurBeliefsPage'
// import EphraimSymbolPage from './pages/about-us/EphraimSymbolPage'
// import WatchOnlinePage from './pages/watch-online/WatchOnlinePage'
// import DonatePage from './pages/donate/DonatePage'
import SermonArchivesPage from './pages/sermons/SermonArchivesPage'
import CalendarPage from './pages/calendar/CalendarPage'
import OurMinistersPage from './pages/our-ministers/OurMinistersPage'

library.add(fab)
library.add([faCalendarAlt, faEnvelope, faFilePdf])
// initializeGoogleAnalytics()
class App extends Component {
    render() {
        if (process.env.REACT_APP_MAINTENANCE_MODE === true) {
            return <p>Coming soon...</p>
        }
        return (
            <Router>
                <Routes>
                    <Route path="/" exact={true} element={<HomePage />} />
                    <Route
                        path="/events"
                        exact={true}
                        element={<UpcomingEventsPage />}
                    />
                    {/* <Route
                        path="/donate"
                        exact={true}
                        component={<DonatePage />}
                    /> */}
                    <Route
                        path="/contact-us"
                        exact={true}
                        element={<ContactUsPage />}
                    />
                    <Route
                        path="/newcomer"
                        exact={true}
                        element={<NewMembersPage />}
                    />
                    <Route
                        path="/what-we-believe"
                        exact={true}
                        element={<OurBeliefsPage />}
                    />

                    <Route
                        path="/calendar"
                        exact={true}
                        element={<CalendarPage />}
                    />
                    <Route
                        path="/our-ministers"
                        exact={true}
                        element={<OurMinistersPage />}
                    />

                    {/* <Route
                        path="/live"
                        exact={true}
                        element={<WatchOnlinePage />}
                    /> */}
                    <Route
                        path="/sermons"
                        exact={true}
                        element={<SermonArchivesPage />}
                    />
                </Routes>
            </Router>
        )
    }
}

export default App
