import React, { Component } from 'react'
import { trackEvent } from '../../services/GoogleApiService'
import './LevelNavigation.scss'

export default class LevelNavigation extends Component {
    state = {
        activeLevel: null,
    }

    componentDidMount() {
        this.setState({ activeLevel: this.props.levels[0] })
    }

    render() {
        const { activeLevel } = this.state
        const { levels } = this.props
        return (
            <div className="level-navigation">
                {levels.map((level, index) => (
                    <div
                        key={index}
                        className={`level-link ${
                            activeLevel &&
                            activeLevel.description === level.description
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => {
                            trackEvent(
                                `Study Guide Level: ${level.description}`
                            )
                            this.setState({ activeLevel: level })
                            this.props.onSelectLevel(index)
                        }}
                    >
                        {level.description}
                    </div>
                ))}
            </div>
        )
    }
}
