import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Footer from '../footer/Footer'
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import './BasePage.scss'
import { Strings } from '../../localizations'

export class BasePage extends Component {
    static propTypes = {
        hideBreadCrumb: PropTypes.bool,
        breadcrumbTitle: PropTypes.string,
        cssClasses: PropTypes.string,
        pageTitle: PropTypes.string,
        children: PropTypes.any,
        fluid: PropTypes.bool,
    }

    render() {
        return (
            <div className={'base-page'}>
                {!this.props.hideBreadCrumb && (
                    <Breadcrumb tag={'nav'} listTag={'div'}>
                        <BreadcrumbItem tag={'a'} href={'/'}>
                            {Strings.home}
                        </BreadcrumbItem>
                        <BreadcrumbItem tag={'span'} active>
                            {this.props.breadcrumbTitle}
                        </BreadcrumbItem>
                        <div className={'page-title'}>
                            <h4>{this.props.pageTitle || 'PAGE TITLE'}</h4>
                        </div>
                    </Breadcrumb>
                )}
                <Container
                    fluid={this.props.fluid || false}
                    className={`base-page-content ${
                        this.props.cssClasses || ''
                    }`}
                >
                    {this.props.children}
                </Container>
                <Footer />
                <div style={{ visibility: 'hidden' }}>
                    {this.props.language}
                </div>
            </div>
        )
    }
}
