import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { BasePage } from '../../components/base-page/BasePage'
import { Strings } from '../../localizations'
import { trackPageView } from '../../services/GoogleApiService'

export default class CalendarPage extends Component {
    componentDidMount() {
        trackPageView(window.location)
    }
    render() {
        return (
            <BasePage
                breadcrumbTitle={Strings.calendar}
                pageTitle={Strings.calendar}
            >
                <Row>
                    <Col>
                        <iframe
                            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FSanto_Domingo&src=YWRtaW5AcmVtbmFudG1lc3NlbmdlcnMuY29t&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4uZG8jaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%230B8043"
                            width="100%"
                            height="768"
                            frameBorder="0"
                            scrolling="no"
                            title={`${process.env.REACT_APP_ORGANIZATION_NAME} Calendar`}
                        ></iframe>
                    </Col>
                </Row>
            </BasePage>
        )
    }
}
