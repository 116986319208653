import { Strings } from '../../localizations'

export const levels = () => [
    {
        description: Strings.foundational_teachings,
        categories: [
            {
                name: Strings.curriculum_header_1,
                playlists: [
                    {
                        title: Strings.purpose_of_temptation,
                        url: './assets/PurposeofTemptation.pdf',
                        description: Strings.temptation_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/temptation.jpeg',
                        tag: Strings.foundational_teachings,
                        comingSoon: false,
                    },
                    {
                        title: Strings.fruit_of_spirit,
                        url: './assets/Sabbath.pdf',
                        description: Strings.fruit_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/fruit-of-spirit.jpeg',
                        tag: Strings.foundational_teachings,
                        comingSoon: true,
                    },
                    // {
                    //     title: Strings.fruit_of_spirit,
                    //     url: './assets/Sabbath.pdf',
                    //     description: Strings.fruit_desc,
                    //     imageUrl:
                    //         'https://d1biklpwskup2.cloudfront.net/fruit-of-spirit.jpeg',
                    //     tag: Strings.foundational_teachings,
                    //     comingSoon: true,
                    // },
                    {
                        title: Strings.war_for_mind,
                        url: './assets/WarMind.pdf',
                        description: Strings.war_mind_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/war-for-mind.jpeg',
                        tag: Strings.foundational_teachings,
                        comingSoon: false,
                    },
                    {
                        title: Strings.sheep_goat_wolves,
                        url: './assets/SheepGoatsWolves.pdf',
                        description: Strings.sheep_goat_wolves_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/wolfsheep.png',
                        tag: Strings.foundational_teachings,
                        comingSoon: false,
                    },
                ],
                additionalResources: [],
            },
            {
                name: Strings.curriculum_header_2,
                playlists: [
                    {
                        title: Strings.keeping_the_sabbath,
                        url: './assets/Sabbath.pdf',
                        description: Strings.sabbath_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/sabbath.jpeg',
                        tag: Strings.foundational_teachings,
                        comingSoon: false,
                    },
                    {
                        title: Strings.everlasting_covenant,
                        url: './assets/EverlastingCovenant.pdf',
                        description: Strings.covenant_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/everlasting-covenant.png',
                        tag: Strings.foundational_teachings,
                        comingSoon: false,
                    },
                    {
                        title: Strings.transition_of_church,
                        url: './assets/TransitionChurch.pdf',
                        description: Strings.transition_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/transition-of-church.png',
                        tag: Strings.foundational_teachings,
                        comingSoon: false,
                    },
                ],
                additionalResources: [],
            },
        ],
    },
    {
        description: Strings.remnant_102,
        categories: [
            {
                name: Strings.curriculum_header_3,
                playlists: [
                    {
                        title: Strings.familiar_spirits,
                        url: './assets/FamiliarSpirits-v1.pdf',
                        description: Strings.familiar_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/familiar-spirits.png',
                        tag: Strings.remnant_102,
                        comingSoon: false,
                    },
                    {
                        title: Strings.divine_guidance,
                        url: './assets/Sabbath.pdf',
                        description: Strings.guidance_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/divine-guidance.png',
                        tag: Strings.remnant_102,
                        comingSoon: true,
                    },
                    {
                        title: Strings.prophets_and_prophecy,
                        url: './assets/Sabbath.pdf',
                        description: Strings.prophets_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/prophets-prophecy.png',
                        tag: Strings.remnant_102,
                        comingSoon: true,
                    },
                ],
                additionalResources: [],
            },
            {
                name: Strings.curriculum_header_4,
                playlists: [
                    {
                        title: Strings.passover,
                        url: './assets/Passover.pdf',
                        description: Strings.passover_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/passover.png',
                        tag: Strings.remnant_102,
                        comingSoon: false,
                    },

                    {
                        title: Strings.feast_of_weeks,
                        url: './assets/Pentecost.pdf',
                        description: Strings.weeks_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/pentecost.png',
                        tag: Strings.remnant_102,
                        comingSoon: false,
                    },

                    {
                        title: Strings.sukkot,
                        url: './assets/Sukkot.pdf',
                        description: Strings.sukkot_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/sukkot.png',
                        tag: Strings.remnant_102,
                        comingSoon: false,
                    },
                    {
                        title: Strings.rosh_hashanah,
                        url: './assets/Rosh_Hashanah.pdf',
                        description: Strings.rosh_hashanah_desc,
                        imageUrl:
                            'https://d1biklpwskup2.cloudfront.net/rosh-hashanah.jpg',
                        tag: Strings.remnant_102,
                        comingSoon: false,
                    },
                ],
            },
        ],
    },
]
