const spanishTranslations = {
    english: 'Inglés        ',
    spanish: 'Español',
    language: 'Idioma',
    calendar: 'Calendario',
    contact_us: 'Contacto',
    about_us: 'Sobre Nosotros',
    getting_started: 'Empezando',
    our_ministers: 'Nuestros Ministros',
    new_here: 'Nuevo Aqui',
    teaching_archive: 'Archivos de Enseñanza',
    upcoming_event: 'Próximo Evento',
    study_outlines: 'Guías de Estudio',
    resources: 'Recursos',
    home: 'Casa',
    next: 'PRÓXIMO',
    all_events: 'Todos los Eventos',
    more_coming_events: 'Más Próximos Eventos',
    featured_sermon_title: 'Funciones del Espíritu Humano',
    featured_sermon_description:
        'La ignorancia de distinguir el espíritu de uno de su alma ha retrasado perpetuamente el crecimiento espiritual válido por generaciones. Por lo tanto, la iglesia ha permanecido en gran parte ALMA, atrapada en la trampa de estar siempre aprendiendo. Dios tenía la intención de que todos los que creen en Él crezcan en Él en todos los aspectos (Ef 4:15). Esta hazaña es imposible a través del proceso de aprendizaje de la mente natural, pero se hace posible a través de los sentidos espirituales del hombre.',
    minister: 'Ministro',
    apostle: 'Apóstol',
    teacher: 'Maestra',
    prophet: 'Profeta',
    evangelist: 'Evangelista',
    pastor: 'Pastor',
    daniel_11_img:
        'https://d1biklpwskup2.cloudfront.net/ES_Beach_Daniel_11_Cover.png',
    joels_army: 'https://d1biklpwskup2.cloudfront.net/ES_+Joel_Army.png',
    view_ministers: 'Ver Nuestros Ministros',
    new_members: 'Nuevos Miembros',
    view_teachings: 'Ver Enseñanzas',
    featured_sermon: 'Sermón Destacado',
    watch_online: 'Ver en linea',
    watch_youtube: 'Ver en Youtube',
    how_to_study: '¿Cómo estudio?',
    foundational_teachings: 'Enseñanzas Fundamentales',
    additional_study: 'Estudio Adicional',
    study_directions: `Aprender los caminos de Dios es como la escuela. Por lo tanto, se nos anima a estudiar para mostrarnos aprobados. Le recomendamos que escuche (o estudie) las enseñanzas cinco veces.
        La primera vez, escucha y sigue con tu biblia. La segunda vez, escuche, siga con su biblia y escriba las escrituras usadas.
        La tercera vez, escucha y escribe el comentario sobre las escrituras utilizadas. La cuarta vez, escuche y anote cualquier comentario que se haya perdido y las preguntas que tenga.
        La quinta vez, repase todas las escrituras junto con su concordancia y busque diferentes puntos que no le quedaron claros.`,
    our_beliefs: 'Nuestras Creencias',
    beliefs1:
        'Creemos que Jesús es el Mesías y el Hijo de Dios Todopoderoso Padre. el vino como el Cordero de Dios para ser el sacrificio perfecto por todos los pecados de la humanidad. Murió y tres días después resucitó de entre los muertos y ahora está sentado a la diestra del Padre. Nosotros creer en la Trinidad, el Padre, el Hijo, y el Espíritu Santo.',
    beliefs2:
        'Creemos en guardar los mandamientos de Dios (Torá), que incluye los designados festividades (es decir, sábado, Rosh Jodesh, Pesaj, Sucot y Yom Teruah). Nosotros no creer que la Ley (Torá) fue abolida cuando Jesús vino a esta tierra. Creemos Jesucristo fue una extensión del pacto que Dios dio a través de la Torá.',
    beliefs3:
        'Creemos en el bautismo del Espíritu Santo con la evidencia de hablar en otros las lenguas separan de la salvación. Creemos en sanidad, liberación, milagros, señales y maravillas',
    beliefs4:
        'Creemos en el uso del quíntuple ministerio y la función de cada uno de estos cinco oficinas dentro de la iglesia. Creemos en la obra de los dones espirituales identificado en 1 Corintios 12 capítulo.',
    mission:
        'Creemos que los días que se avecinan para el mundo se van a convertir en tiempos muy difíciles. La iglesia se está hundiendo cada vez más en un coma del cual sólo un remanente despertará. Es este remanente el que hará las grandes hazañas en estos últimos días. Nuestro objetivo es ayudar al remanente a identificarse y prepararse para el regreso de nuestro Señor y Salvador Jesucristo.',
    purpose_of_temptation: 'Propósito de la Tentación',
    curriculum_header_1: 'La tentación, El buen fruto y La guerra mental',
    curriculum_header_2: 'El Pacto de Dios y las Fiestas',
    curriculum_header_3: 'Oración y Guerra Espiritual',
    curriculum_header_4: 'Festivales Adicionales',
    fruit_of_spirit: 'Fruto del Espíritu',
    war_for_mind: 'Guerra por tu mente',
    keeping_the_sabbath: 'Guardar el Sábado',
    everlasting_covenant: 'Pacto Eterno',
    transition_of_church: 'Transición de la Iglesia',
    familiar_spirits: 'Espíritus familiares',
    remnant_102: 'Remanente 102',
    divine_guidance: 'Guía Divina',
    prophets_and_prophecy: 'Profetas y Profecía',
    passover: 'La Fiesta de la Pascua',
    feast_of_weeks: 'La fiesta de las semanas',
    sukkot: 'La fiesta de Sucot',
    read_more: 'Leer Más...',
    coming_soon: 'Muy pronto...',
    temptation_desc:
        '¿Por qué Dios permite que seamos tentados? Es parte de la Ley del Aumento. Para ganar, debes ser probado.',
    fruit_desc:
        'Se espera que produzcamos frutos. Sin fruto, tu caminar con Cristo no existe. Aprende los atributos de cada fruta',
    war_mind_desc:
        'Tu mente es el mayor enemigo que tienes. A menudo es la razón de la falta de crecimiento espiritual. Aprende a dominar tu mente.',
    sabbath_desc:
        'El sábado fue dado a la humanidad como una señal de que eres uno de Sus hijos. Aprenda acerca de las bendiciones de observar este día sagrado.',
    covenant_desc:
        'Dios creó un pacto. Jesús fue una extensión del pacto, no el final. Aprenda lo que obstaculiza las bendiciones de muchos cristianos',
    transition_desc:
        'Dios trae el conocimiento de la revelación a cada generación para acercar la iglesia a Su imagen. La iglesia está una vez más en transición.',
    familiar_desc:
        'Hay espíritus de las tinieblas que te son familiares. Potencialmente han estado en su familia durante generaciones. Aprende a superarlos.',
    guidance_desc:
        'Cada uno de nosotros necesita ser guiado por Dios. Pero hay reglas a seguir para evitar errores clave. Aprende las reglas de cómo Dios nos guía.',
    prophets_desc:
        '¿Cómo identificas a los verdaderos profetas? ¿Cuáles son las consecuencias de escuchar a los falsos profetas? Aprende las reglas aquí.',
    passover_desc:
        'La Pascua es un festival de siete días en el que celebramos cómo un Dios poderoso sacó a nuestros antepasados de Egipto. Aprenda sobre su historia y su importancia para hoy',
    weeks_desc:
        'Pentecostés es un festival de un día en el que se conmemora la entrega de la Torá y la entrega del Espíritu Santo. Aprenda sobre su historia e importancia para hoy',
    sukkot_desc:
        'Sucot es una fiesta de ocho días en la que recordamos que Dios hizo que nuestros antepasados habitaran en tiendas cuando los sacó de Egipto. Conozca su historia y su importancia para la actualidad.',
    larry_bio: `Originario de Dallas, TX, Larry creció como hijo de un pastor en un
    Iglesia Pentecostal/Santidad. Desafortunadamente, esta educación junto con su experiencia cristiana adulta le hizo observar lo bueno, lo malo y lo feo de la iglesia.
    vida. La mirada entre bastidores a los desacuerdos doctrinales, las disputas financieras y las luchas internas por el poder le causaron una profunda insatisfacción por la iglesia/religión.
    Esto casi lo hizo abandonar a Dios por completo. En 2008, asistió a un servicio en el que fue testigo de cómo un profeta imponía las manos sobre las personas y manifestaba milagros.
    Crecieron piernas cortas, se curaron enfermedades y se expulsaron demonios. Saltó de emoción porque ahora sabía: "Dios no está muerto".
    Dios usó esto para dar a luz un hambre profunda de intimidad personal con Él a través del estudio de Su palabra, el ayuno y la oración.
    Larry luego pasó los siguientes 12 años sirviendo y entrenando bajo el ministerio profético. En octubre de 2019, tres profetas lo ordenaron y comisionaron como Apóstol y Profeta del cuerpo de Cristo.
    a la vista de muchos testigos según Hechos 13:2 y 1 Timoteo 4:14-16.
    Su enfoque ahora está en enseñar una intimidad más profunda con Dios. Él está interesado en crear discípulos maduros de Cristo, no creyentes en una doctrina en particular.`,
    jawana_bio: `Criada en Minnesota, Jawana creció muy involucrada en una iglesia no denominacional.
    Después de servir y aprender bajo el ministerio durante casi 12 años, fue ordenada y comisionada como maestra del cuerpo de Cristo.
    a la vista de muchos testigos según Hechos 13:2 y 1 Timoteo 4:14-16. Sus pasiones incluyen ministrar a los niños y alentar a los creyentes a esforzarse por alcanzar la madurez en Cristo.`,
    jon_bio: `Originario de Chelsea, Londres, hermano
    Stevens actualmente funciona como evangelista 
    al cuerpo de Cristo.`,
    trevor_bio: `Originario de California, el hermano Cole
    actualmente funciona como pastor del cuerpo
    de Cristo`,
    roger_bio: `Originario de Caracas, Venezuela, Hermano
    Gavidia actualmente funciona como maestra de
    El cuerpo de Cristo.`,
    meet_our_team: 'Conozca a nuestro equipo',
    shabbat_service: 'Servicio de Shabat',
    email_success:
        '¡Gracias por tu mensaje! Responderemos tan pronto como podamos.',
    the_trinity: 'La Trinidad',
    the_law: 'La Ley (Torá)',
    holy_spirit: 'El Espíritu Santo',
    offices_and_gifts: 'Dones y Llamamientos',
    rosh_hashanah: 'Rosh Hashaná',
    rosh_hashanah_desc:
        'Rosh Hashaná "El Año Nuevo" en hebreo. Una fiesta que se nos ordena guardar. Estos días santos profundamente espirituales conmemoran la creación del mundo. Un tiempo para arrepentirse. Un tiempo para reflexionar sobre las elecciones que ha hecho.',
    sheep_goat_wolves: 'Ovejas, cabras y lobos',
    sheep_goat_wolves_desc:
        'Se nos advierte que tengamos cuidado porque los falsos representantes de Cristo vendrán entre nosotros con vestidos de ovejas. Eres una oveja, una cabra o un lobo en lo que se refiere al rebaño de Dios.',
    jonathan_bio:
        'Nacido y criado en el área de Washington DC, Jonathan creció en una iglesia no denominacional. Después de años de asistir, ser voluntario y ser mentor dentro de esa iglesia, no vio que el mismo Dios que le habían enseñado fuera ejemplificado por sus miembros o líderes. En 2007, esta crisis de fe le hizo abandonar la iglesia y buscar sentido al alistarse en el Cuerpo de Marines de los Estados Unidos; cumplió 4 años y participó en dos giras a Afganistán como soldado de infantería. Después de los Marines, todavía estaba buscando un significado y una dirección que no podía encontrar dentro de la iglesia o los Marines. En enero de 2012 asistió a una conferencia de fin de semana con un profeta que tenía un ministerio de liberación y vio operar el poder de Dios. Ha buscado a Dios desde entonces. Fue ordenado ministerio como maestro en abril de 2020.',
    fred_bio:
        'Nacido en Takoma Park, Maryland, Fred creció en una iglesia adventista del séptimo día. Fue ordenado ministerio como pastor en abril de 2020.',
    paul_bio:
        'Nacido y criado en Francia, Paul escapó de una historia familiar de brujería y entregó su vida a Cristo a principios de los años 1980. Fue discipulado personalmente por un apóstol y profeta durante varios años. En 2010, fue ordenado y comisionado como Apóstol y Profeta del cuerpo de Cristo ante muchos testigos según Hechos 13:2 y 1 Timoteo 4:14-16. Se especializa en brindar conocimiento de revelación y visión profética sobre el fin de los tiempos.',
    daren_bio:
        'Nacido y criado en el área de Massachusetts, Daren fue ordenado ministerio como evangelista en octubre de 2019.',
}

export default spanishTranslations
