import React, { Component } from 'react'
import { Row, Col, Container, NavLink, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './NoticeBar.scss'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Strings } from '../../localizations'

export class NoticeBar extends Component {
    static propTypes = {
        eventDate: PropTypes.instanceOf(Date).isRequired,
        eventName: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            daysUntil: 0,
            hoursUntil: 0,
            minutesUntil: 0,
            secondsUntil: 0,
        }
    }

    componentDidMount() {
        this.interval = setInterval(this.calculateTimeSince, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    calculateTimeSince = () => {
        const eventDate = moment(this.props.eventDate)
        const now = moment()
        const duration = moment.duration(eventDate.diff(now))
        //Get Days and subtract from duration
        let days = duration.asDays().toFixed(0)
        duration.subtract(moment.duration(days, 'days'))

        //Get hours and subtract from duration
        let hours = duration.hours()
        duration.subtract(moment.duration(hours, 'hours'))

        //Get Minutes and subtract from duration
        let minutes = duration.minutes()
        duration.subtract(moment.duration(minutes, 'minutes'))

        let seconds = duration.seconds()

        this.setState({
            daysUntil: days < 0 ? 0 : days,
            hoursUntil: hours < 0 ? 0 : hours,
            minutesUntil: minutes < 0 ? 0 : minutes,
            secondsUntil: seconds < 0 ? 0 : seconds,
        })
    }

    render() {
        const { eventDate, eventName } = this.props
        const { daysUntil, hoursUntil, minutesUntil, secondsUntil } = this.state
        return (
            <div className={'notice-bar'}>
                <Container>
                    <Row>
                        <Col
                            xs={'6'}
                            sm={'6'}
                            md={'3'}
                            className={'notice-bar-title'}
                        >
                            <FontAwesomeIcon
                                icon={['fa', 'calendar-alt']}
                                className={
                                    'notice-bar-title-icon d-none d-md-block'
                                }
                                size={'3x'}
                            />
                            <span className="title-note">{Strings.next}</span>
                            <strong>{Strings.upcoming_event}</strong>
                        </Col>
                        <Col
                            xs={'6'}
                            sm={'6'}
                            md={'3'}
                            className={'notice-bar-event-title'}
                        >
                            <h5>
                                <a href="/">{eventName}</a>
                            </h5>
                            <span className="meta-data">
                                {moment(eventDate).format('Do MMMM, YYYY')}
                            </span>
                        </Col>
                        <Col xs={'12'} sm={'6'} md={'4'} className={'counter'}>
                            <div className="timer-col">
                                <span id="days">{daysUntil}</span>{' '}
                                <span className="timer-type">days</span>
                            </div>
                            <div className="timer-col">
                                <span id="hours">{hoursUntil}</span>{' '}
                                <span className="timer-type">hrs</span>
                            </div>
                            <div className="timer-col">
                                <span id="minutes">{minutesUntil}</span>{' '}
                                <span className="timer-type">mins</span>
                            </div>
                            <div className="timer-col">
                                <span id="seconds">{secondsUntil}</span>{' '}
                                <span className="timer-type">secs</span>
                            </div>
                        </Col>
                        <Col sm={'12'} md={'2'}>
                            <NavLink href={'/'}>
                                <Button className="notice-bar-btn">
                                    <small className={'font-weight-bold'}>
                                        {Strings.all_events}
                                    </small>
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
