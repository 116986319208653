import LocalizedStrings from 'react-localization'
import en from './en'
import es from './es'

const Strings = new LocalizedStrings({ en, es })
const language = localStorage.getItem('language') || 'en'
Strings.setLanguage(language)

if (!language) {
    localStorage.setItem('language', 'en')
}

export { Strings }
