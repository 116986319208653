import React, { Component } from 'react'
import { BasePage } from '../../components/base-page/BasePage'
import LevelNavigation from './LevelNavigation'
import { Col, Row } from 'reactstrap'
import './NewMembersPage.scss'
import { trackPageView } from '../../services/GoogleApiService'
import PlaylistCategory from '../../components/playlist-category/PlaylistCategory'
import { connect } from 'react-redux'
import { Strings } from '../../localizations'
import { Worker } from '@react-pdf-viewer/core'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export class NewMembersPage extends Component {
    componentDidMount() {
        trackPageView(window.location)
    }

    state = {
        activeLevel: 0,
    }

    onSelectLevel = (activeLevel) => {
        this.setState({ activeLevel })
    }

    render() {
        const { activeLevel } = this.state
        return (
            <BasePage
                breadcrumbTitle={Strings.new_here}
                cssClasses={'new-members'}
                pageTitle={Strings.getting_started}
                fluid={true}
            >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                    <Row>
                        <Col>
                            <h3>{Strings.how_to_study}</h3>
                            <p>{Strings.study_directions}</p>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col md={'3'}>
                            <LevelNavigation
                                levels={this.props.curriculumLevels}
                                onSelectLevel={this.onSelectLevel}
                            />
                        </Col>

                        <Col md={'9'}>
                            {this.props.curriculumLevels[
                                activeLevel
                            ].categories.map((category, index) => {
                                return (
                                    <PlaylistCategory
                                        key={index}
                                        category={category}
                                    />
                                )
                            })}
                        </Col>
                    </Row>
                </Worker>
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        curriculumLevels: state.curriculumLevels,
    }
}

export default connect(mapStateToProps)(NewMembersPage)
