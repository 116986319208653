import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import './UpcomingEventsPage.scss'
import { BasePage } from '../../components/base-page/BasePage'
import { trackPageView } from '../../services/GoogleApiService'
import { connect } from 'react-redux'
export class UpcomingEventsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
        }

        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        trackPageView(window.location)
    }

    toggle() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    render() {
        return (
            <BasePage breadcrumbTitle={'Events'} pageTitle={'Events'}>
                <Row>
                    <Col>
                        <Row>
                            <Col></Col>
                        </Row>
                    </Col>
                </Row>
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(UpcomingEventsPage)
