import React, { Component } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap'
import './OurMinistersPage.scss'
import { BasePage } from '../../components/base-page/BasePage'
import { Strings } from '../../localizations'
import { connect } from 'react-redux'
import { trackEvent, trackPageView } from '../../services/GoogleApiService'

export class OurMinistersPage extends Component {
    componentDidMount() {
        trackPageView(window.location)
    }

    state = {
        selectedImg: '',
        selectedBio: '',
        selectedName: '',
        showModal: false,
    }

    toggle = () => {
        this.setState({
            showModal: false,
            selectedBio: '',
            selectedImg: '',
            selectedName: '',
        })
    }

    setActiveProfile = (attrs) => {
        this.setState({
            ...attrs,
            showModal: true,
        })
    }

    render() {
        return (
            <BasePage
                breadcrumbTitle={'Our Ministers'}
                pageTitle={'Our Ministers'}
                cssClasses={'our-ministers'}
            >
                {/* <Row className={'my-5'}>
                    <Col>
                        <Card className="my-2 group-ministers">
                            <CardImg
                                alt="Card image cap"
                                src="https://d1biklpwskup2.cloudfront.net/Group_Ministers.jpg"
                                top
                            />
                            <CardBody>
                                <CardTitle tag="h5">
                                    {Strings.meet_our_team}
                                </CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
                <Row>
                    <Col className="offset-lg-2">
                        <Card body>
                            <img
                                className={'minister-profile-pic'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Hendrix_Larry.jpg"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Larry A. Hendrix
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {`${Strings.apostle} & ${Strings.prophet}`}
                                </CardSubtitle>
                                <CardText>{Strings.larry_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Larry Hendrix Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Hendrix_Larry.jpg',
                                            selectedBio: Strings.larry_bio,
                                            selectedName: 'Larry A. Hendrix',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Hendrix_Jawana.jpg"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Jawana Hendrix
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {Strings.teacher}
                                </CardSubtitle>
                                <CardText>{Strings.jawana_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Jawana Hendrix Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Hendrix_Jawana.jpg',
                                            selectedBio: Strings.jawana_bio,
                                            selectedName: 'Jawana Hendrix',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="offset-lg-2">
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Stevens_Jon.jpg"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Jon Stevens
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {Strings.evangelist}
                                </CardSubtitle>
                                <CardText>{Strings.jon_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Jon Stevens Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Stevens_Jon.jpg',
                                            selectedBio: Strings.jon_bio,
                                            selectedName: 'Jon Stevens',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col>
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Gavidia_Roger.jpg"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Roger Gavidia
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {Strings.teacher}
                                </CardSubtitle>
                                <CardText>{Strings.roger_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Roger Gavidia Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Gavidia_Roger.jpg',
                                            selectedBio: Strings.roger_bio,
                                            selectedName: 'Roger Gavidia',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="offset-lg-2">
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/favret_Paul.png"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Paul Favret
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {`${Strings.apostle} & ${Strings.prophet}`}
                                </CardSubtitle>
                                <CardText>{Strings.paul_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Paul Favret Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/favret_Paul.png',
                                            selectedBio: Strings.paul_bio,
                                            selectedName: 'Paul Favret',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Reiss_Fred.png"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Fred Reiss
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {Strings.pastor}
                                </CardSubtitle>
                                <CardText>{Strings.fred_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Fred Reiss Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Reiss_Fred.png',
                                            selectedBio: Strings.fred_bio,
                                            selectedName: 'Fred Reiss',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="offset-lg-2">
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Reiss_Jon.png"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Jonathan Reiss
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {Strings.teacher}
                                </CardSubtitle>
                                <CardText>{Strings.jonathan_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Jonathan Reiss Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Reiss_Jon.png',
                                            selectedBio: Strings.jonathan_bio,
                                            selectedName: 'Jonathan Reiss',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card body>
                            <img
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Altieri_Daren.png"
                                className={'minister-profile-pic'}
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Daren Altieri
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 minister-office"
                                    tag="h6"
                                >
                                    {Strings.evangelist}
                                </CardSubtitle>
                                <CardText>{Strings.daren_bio}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Daren Altieri Minister Details'
                                        )
                                        this.setActiveProfile({
                                            selectedImg:
                                                'https://d1biklpwskup2.cloudfront.net/Altieri_Daren.png',
                                            selectedBio: Strings.daren_bio,
                                            selectedName: 'Daren Altieri',
                                        })
                                    }}
                                >
                                    {Strings.read_more}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    size={'lg'}
                    onClosed={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>
                        {this.state.selectedName}
                    </ModalHeader>
                    <ModalBody>
                        <img
                            alt="Sample"
                            src={this.state.selectedImg}
                            className={'featured-img'}
                        />
                        {this.state.selectedBio}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(OurMinistersPage)
