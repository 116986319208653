import React, { Component } from 'react'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    Row,
} from 'reactstrap'
import './OurBeliefsPage.scss'
import { BasePage } from '../../components/base-page/BasePage'
import { Strings } from '../../localizations'
import { connect } from 'react-redux'
import { trackPageView } from '../../services/GoogleApiService'

export class OurBeliefsPage extends Component {
    componentDidMount() {
        trackPageView(window.location)
    }

    state = {
        open: '1',
    }
    toggle = (id) => {
        if (this.state.open === id) {
            this.setState({ open: '' })
        } else {
            this.setState({ open: id })
        }
    }
    render() {
        return (
            <BasePage
                breadcrumbTitle={Strings.our_beliefs}
                pageTitle={Strings.our_beliefs}
                fluid={true}
                cssClasses={'mt-n3'}
            >
                <Row className={'our-beliefs'}>
                    <Col>
                        <Row className={'mx-n5'}>
                            <Col className={'text-center'}>
                                <img
                                    src="https://d1biklpwskup2.cloudfront.net/what-we-believe.png"
                                    alt="our beliefs"
                                    width={'100%'}
                                    height={'350px'}
                                />
                            </Col>
                        </Row>
                        <Row className={'my-5'}>
                            <Col>
                                <h1 className={'theme-text'}>Our Mission</h1>
                                <h5>{Strings.mission}</h5>
                            </Col>
                        </Row>
                        <Row className={'my-5'}>
                            <Col>
                                <h1 className={'theme-text'}>
                                    {Strings.our_beliefs}
                                </h1>
                                <Accordion
                                    open={this.state.open}
                                    toggle={this.toggle}
                                >
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            <h3>{Strings.the_trinity}</h3>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            <h5>{Strings.beliefs1}</h5>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                            <h3>{Strings.the_law}</h3>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                            <h5>{Strings.beliefs2}</h5>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                            <h3>{Strings.holy_spirit}</h3>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                            <h5>{Strings.beliefs3}</h5>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="4">
                                            <h3>{Strings.offices_and_gifts}</h3>
                                        </AccordionHeader>
                                        <AccordionBody accordionId="4">
                                            <h5>{Strings.beliefs4}</h5>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(OurBeliefsPage)
